import React from "react";
import Helmet from "react-helmet";
import classNames from "classnames";
import { graphql } from "gatsby";

// Components.
import Layout from "../components/layout";
import Img from "gatsby-image";
// Assets.
import ContentStyles from "../components/css-modules/content.module.scss";

const HowWork = ({ data }) => {
  // Fetch images.
  const { image1, image2 } = data;

  return (
    <Layout>
      <Helmet>
        <title>Werkwijze</title>
      </Helmet>

      <article className={ContentStyles.pageStyle}>
        <div
          className={classNames(
            ContentStyles.imageGrid,
            ContentStyles.imageRight,
            ContentStyles.spacingBelow
          )}
        >
          <div className={ContentStyles.imageGridContent}>
            <h1>Werkwijze</h1>

            <p>
              Mijn werkwijze is flexibel en stem ik af op de wensen en behoeften
              van jou als ondernemer. Voor een vast bedrag schuif ik een X
              aantal uur per week aan bij jou op kantoor. Door daadwerkelijk bij
              je over de werkvloer te komen, sta ik middenin de organisatie,
              weet ik wat er speelt, kan ik snel op zaken inspelen, ter plekke
              antwoord geven op vragen, en zorgen dat de vaart erin blijft. Op
              deze manier sta ik (letterlijk) naast jou als ondernemer en maak
              ik deel uit van je team.
            </p>
          </div>

          <Img
            fluid={image1.childImageSharp.fluid}
            alt="Foto van Simon Daans in vergaderruimte"
          />
        </div>

        <div
          className={classNames(
            ContentStyles.imageGrid,
            ContentStyles.imageLeft,
            ContentStyles.spacingBelow
          )}
        >
          <Img
            fluid={image2.childImageSharp.fluid}
            alt="Daans maakt duidelijke afspraken"
          />

          <div className={ContentStyles.imageGridContent}>
            <h2>Duidelijke afspraken</h2>
            <p>
              Als geen ander begrijp ik dat je de touwtjes niet zomaar uit
              handen geeft. Ik sta bekend om mijn integriteit; ik hou van
              duidelijke afspraken en hecht veel waarde aan een eerlijke en
              oprechte relatie met mijn opdrachtgevers. Het gaat erom dat jij de
              juiste adviezen krijgt. Denk ik dat iemand anders je beter kan
              helpen met een bepaald vraagstuk? Dan koppel ik je graag aan de
              juiste specialist.
            </p>
          </div>
        </div>
      </article>
    </Layout>
  );
};

export const frontmatter = {
  title: `Werkwijze`,
  path: `/werkwijze`,
  description: ``,
  weight: 20
};

export default HowWork;

export const fluidImage = graphql`
  fragment fluidImageWork on File {
    childImageSharp {
      fluid(maxWidth: 800, quality: 85) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
        presentationWidth
      }
    }
  }
`;

export const query = graphql`
  query {
    image1: file(
      relativePath: { eq: "foto-daans-overleg.jpg" }
      sourceInstanceName: { eq: "images" }
    ) {
      ...fluidImage
    }
    image2: file(
      relativePath: { eq: "afspraken.jpg" }
      sourceInstanceName: { eq: "images" }
    ) {
      ...fluidImage
    }
  }
`;
